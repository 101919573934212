
import { defineComponent, PropType, watch } from "vue";
import { Document, Movement } from "@/graphql/types";
import { activeActivity } from "@/plugins/i18n";
import { activityLogo, formatDate } from "@/graphql/utils/utils";
import { printElement } from "@/graphql/ticket/ticket.printer";

export default defineComponent({
  name: "DocumentPrinter",
  props: {
    selected: Object as PropType<Document>,
    modelValue: Boolean,
    movements: Array as PropType<Movement[]>,
  },
  emits: ["update:modelValue", "update:selected"],
  setup(props, { emit }) {
    watch(
      () => props.modelValue,
      (v) => {
        if (v) {
          setTimeout(() => printElement("print-ticket"), 50);
          setTimeout(() => {
            emit("update:selected", null);
            emit("update:modelValue", false);
          }, 60);
        }
      },
      { immediate: true }
    );
    return {
      activeActivity,
      activityLogo,
      formatDate,
    };
  },
});
