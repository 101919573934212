import {
  MutationUpdateQuantitiesArgs,
  Stock,
  UpdateQuantityInput,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { STOCK_FIELDS } from "@/graphql/stock/stock";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { reactive, ref } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { FilterMatchMode, FilterOperator } from "primevue/api";

type UpdateQuantitiesData = {
  updateQuantities: Stock[];
};

const UPDATE_QUANTITIES = gql`
    mutation UpdateQuantities($input: UpdateQuantitiesInput!) {
        updateQuantities(input: $input) {
            ${STOCK_FIELDS}
        }
    }
`;
export const STOCK_QUANTITY_FILTERS = {
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  "product.reference": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  "product.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  "product.category.id": {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  quantity: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export type StockRowQuantity = Stock & {
  nq: number;
};
export const useUpdateQuantities = (callback: () => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm_stock = ref<any>(null);
  const editingRows = ref([]);
  const dialog = reactive({
    show: false,
    stock: null,
    sign: 1,
    quantity: 0,
    index: 0,
  });
  const {
    loading: qLoading,
    mutate,
    onDone,
  } = useMutation<UpdateQuantitiesData, MutationUpdateQuantitiesArgs>(
    UPDATE_QUANTITIES
  );
  onDone(({ data }) => {
    const status = data?.updateQuantities?.length > 0;
    toast.add({
      severity: status ? "success" : "warn",
      summary: t("stock.update.title"),
      detail: t(`stock.update.${status ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    if (status && dialog.quantity == 0) callback();
    dialog.quantity = 0;
  });
  function submit(input: UpdateQuantityInput[], type: number) {
    if (input.length > 0) {
      void mutate({
        input: {
          input,
          type,
          activityId: activeActivity.value.id,
          createdAt: new Date(),
        },
      });
    } else {
      toast.add({
        severity: "info",
        summary: t("stock.update.title"),
        detail: t("noChange"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  }
  function updateQuantities() {
    const input = editingRows.value.reduce((acc, cur) => {
      if (cur.cq !== cur.nq) {
        acc.push({ id: cur.id, quantity: cur.nq });
        return acc;
      }
      return acc;
    }, []);
    submit(input, 0); // from stock
  }
  function updateQuantities2(rows: StockRowQuantity[]) {
    const input = rows.reduce((acc, cur) => {
      if (cur.quantity !== cur.nq) {
        acc.push({ id: cur.id, quantity: cur.nq });
        return acc;
      }
      return acc;
    }, []);
    submit(input, 1); // from inventory
  }

  function updateQuantities3(rows: StockRowQuantity[]) {
    const input = rows.reduce((acc, cur) => {
      if (cur.nq) {
        acc.push({ id: cur.id, quantity: cur.nq });
        return acc;
      }
      return acc;
    }, []);
    submit(input, 3); // from Entry stock
  }

  function updateQuantities4(rows: StockRowQuantity[]) {
    const input = rows.reduce((acc, cur) => {
      if (cur.nq) {
        acc.push({ id: cur.id, quantity: -cur.nq });
        return acc;
      }
      return acc;
    }, []);
    submit(input, 4); // from Out stock
  }

  function openConfirmStock(event: any, index: number, stock: Stock) {
    const id = (event.currentTarget as HTMLElement).id;
    dialog.sign = id === "minus_stock" ? -1 : 1;
    dialog.stock = stock;
    dialog.quantity = null;
    dialog.index = index;
    confirm_stock.value.toggle(event);
  }

  function confirmStock() {
    if (!dialog.quantity) return;
    const delta = dialog.quantity * dialog.sign;
    editingRows.value[dialog.index].nq = dialog.stock.quantity + delta;
    confirm_stock.value.hide();
  }

  return {
    qLoading,
    updateQuantities,
    editingRows,
    updateQuantities2,
    updateQuantities3,
    updateQuantities4,
    openConfirmStock,
    confirm_stock,
    confirmStock,
    dialog,
  };
};
