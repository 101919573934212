import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-text-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_NumberFormatter = _resolveComponent("NumberFormatter")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    class: "p-datatable-sm",
    "show-gridlines": "",
    responsiveLayout: "scroll",
    value: _ctx.movements,
    filters: _ctx.filters,
    "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
    filterDisplay: "menu"
  }, _createSlots({
    header: _withCtx(() => [
      _createVNode(_component_Button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('setDocument'))),
        label: _ctx.$t('back'),
        icon: "pi pi-arrow-left"
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        type: "button",
        icon: "pi pi-filter-slash",
        label: _ctx.$t('clear'),
        class: "p-button-outlined p-mx-3",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearFilter()))
      }, null, 8, ["label"]),
      _createElementVNode("span", _hoisted_1, " Détails du document " + _toDisplayString(_ctx.$t("number", { number: _ctx.selected.number })) + " - " + _toDisplayString(_ctx.$d(_ctx.selected.createdAt, "short")), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        "show-add-button": false,
        showFilterOperator: false,
        header: _ctx.$t('stock.product'),
        filterField: "product.name",
        field: "product.name",
        sortable: true,
        "sort-field": "product.name"
      }, {
        filter: _withCtx(({ filterModel }) => [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            class: "p-column-filter",
            placeholder: _ctx.$t('product.filterByName')
          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            icon: "pi pi-times",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-warning p-mr-1",
            label: _ctx.$t('prime.clear')
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            icon: "pi pi-search",
            class: "p-button-success",
            label: _ctx.$t('search'),
            onClick: ($event: any) => (filterCallback())
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        "sort-field": "price",
        sortable: true,
        field: "price",
        header: _ctx.$t('stock.price'),
        "show-add-button": false,
        showFilterOperator: false,
        "filter-field": "price",
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          _createVNode(_component_NumberFormatter, {
            value: data.price
          }, null, 8, ["value"]),
          _createTextVNode(" " + _toDisplayString(_ctx.currency), 1)
        ]),
        filter: _withCtx(({ filterModel }) => [
          _createVNode(_component_InputNumber, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            "max-fraction-digits": 20,
            min: 0,
            mode: "decimal"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-warning p-button-sm",
            label: _ctx.$t('prime.clear')
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            label: _ctx.$t('search'),
            type: "button",
            icon: "pi pi-search",
            class: "p-button-sm",
            onClick: ($event: any) => (filterCallback())
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        "sort-field": "initial",
        sortable: true,
        field: "initial",
        header: _ctx.$t('stock.initial'),
        "show-add-button": false,
        showFilterOperator: false,
        "filter-field": "initial",
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          _createVNode(_component_NumberFormatter, {
            value: data.initial
          }, null, 8, ["value"])
        ]),
        filter: _withCtx(({ filterModel }) => [
          _createVNode(_component_InputNumber, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            "max-fraction-digits": 20,
            min: 0,
            mode: "decimal"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-warning p-button-sm",
            label: _ctx.$t('prime.clear')
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            label: _ctx.$t('search'),
            type: "button",
            icon: "pi pi-search",
            class: "p-button-sm",
            onClick: ($event: any) => (filterCallback())
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        "sort-field": "quantity",
        sortable: true,
        field: "quantity",
        header: _ctx.$t('stock.quantity'),
        "show-add-button": false,
        showFilterOperator: false,
        "filter-field": "quantity",
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          _createTextVNode(_toDisplayString(data.motif > 0 ? "+" : "") + " ", 1),
          _createVNode(_component_NumberFormatter, {
            value: data.quantity
          }, null, 8, ["value"])
        ]),
        filter: _withCtx(({ filterModel }) => [
          _createVNode(_component_InputNumber, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            "max-fraction-digits": 20,
            mode: "decimal"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-warning p-button-sm",
            label: _ctx.$t('prime.clear')
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            label: _ctx.$t('search'),
            type: "button",
            icon: "pi pi-search",
            class: "p-button-sm",
            onClick: ($event: any) => (filterCallback())
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        "sort-field": "qStock",
        sortable: true,
        field: "qStock",
        header: _ctx.$t('stock.final'),
        "show-add-button": false,
        showFilterOperator: false,
        "filter-field": "qStock",
        dataType: "numeric"
      }, {
        body: _withCtx(({ data }) => [
          _createVNode(_component_NumberFormatter, {
            value: data.qStock
          }, null, 8, ["value"])
        ]),
        filter: _withCtx(({ filterModel }) => [
          _createVNode(_component_InputNumber, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            "max-fraction-digits": 20,
            min: 0,
            mode: "decimal"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-warning p-button-sm",
            label: _ctx.$t('prime.clear')
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            label: _ctx.$t('search'),
            type: "button",
            icon: "pi pi-search",
            class: "p-button-sm",
            onClick: ($event: any) => (filterCallback())
          }, null, 8, ["label", "onClick"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "stock.expiredAt",
        "show-add-button": false,
        showFilterOperator: false,
        header: _ctx.$t('stock.expiredAt'),
        dataType: "date",
        "filter-field": "stock.expiredAt",
        sortable: true,
        "sort-field": "stock.expiredAt"
      }, {
        body: _withCtx(({ data }) => [
          _createTextVNode(_toDisplayString(data.stock.expiredAt && _ctx.$d(data.stock.expiredAt)), 1)
        ]),
        filter: _withCtx(({ filterModel, filterCallback }) => [
          _createVNode(_component_Calendar, {
            modelValue: filterModel.value,
            "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
            dateFormat: _ctx.$t('format.calendarDate'),
            placeholder: _ctx.$t('format.date'),
            "year-navigator": true,
            onDateSelect: ($event: any) => (filterCallback())
          }, null, 8, ["modelValue", "onUpdate:modelValue", "dateFormat", "placeholder", "onDateSelect"])
        ]),
        filterclear: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            icon: "pi pi-times",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-warning p-mr-1",
            label: _ctx.$t('prime.clear')
          }, null, 8, ["onClick", "label"])
        ]),
        filterapply: _withCtx(({ filterCallback }) => [
          _createVNode(_component_Button, {
            type: "button",
            icon: "pi pi-search",
            onClick: ($event: any) => (filterCallback()),
            class: "p-button-success",
            label: _ctx.$t('search')
          }, null, 8, ["onClick", "label"])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 2
  }, [
    (_ctx.selected.deletedAt)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_Tag, {
              severity: "danger",
              value: 
          _ctx.$t('document.deletedAt') + ' ' + _ctx.$d(_ctx.selected.deletedAt, 'short')
        ,
              icon: "pi pi-info-circle"
            }, null, 8, ["value"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["value", "filters"]))
}