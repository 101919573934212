import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { MOVEMENT_FIELDS } from "@/graphql/movement/movement";
import {
  MutationRemoveDocumentArgs,
  RemoveDocumentOutput,
} from "@/graphql/types";

type RemoveDocumentData = {
  removeDocument: RemoveDocumentOutput;
};

const REMOVE_DOCUMENT = gql`
    mutation RemoveDocument($id: String!) {
        removeDocument(id: $id) {
            document {
              id
              deletedAt
              movements{
                ${MOVEMENT_FIELDS}
              }
            }
            stocks {
              id
              quantity
            }
        }
    }
`;

export const useRemoveDocument = () => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();
  const {
    loading: removeDocumentLoading,
    mutate,
    onDone,
  } = useMutation<RemoveDocumentData, MutationRemoveDocumentArgs>(
    REMOVE_DOCUMENT
  );
  onDone(({ data }) => {
    const document = data?.removeDocument?.document;
    toast.add({
      severity: document ? "success" : "warn",
      summary: t("delete.title"),
      detail: t(`document.remove.${document ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  async function removeDocument(id: string) {
    const data = {
      header: t("delete.title"),
      message: t("document.remove.warning"),
      group: "dialog",
      accept: () => {
        void mutate({ id });
      },
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-warning",
    };
    confirm.require(data);
  }
  return { removeDocumentLoading, removeDocument };
};
