import {
  MutationDeleteStockArgs,
  Product,
  QueryProductsArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";
import { cloneDeep } from "@/graphql/utils/utils";
import { PRODUCTS, ProductsData } from "@/graphql/product/products";
import { activeActivity } from "@/plugins/i18n";

type DeleteStockData = {
  deleteStock: number;
};

const DELETE_STOCK = gql`
  mutation DeleteStock($id: String!) {
    deleteStock(id: $id)
  }
`;

export const useDeleteStock = () => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();
  const {
    loading: removeLoading,
    onError,
    onDone,
    mutate,
  } = useMutation<DeleteStockData, MutationDeleteStockArgs>(DELETE_STOCK);
  onDone(({ data, errors }) => {
    if (data?.deleteStock) {
      toast.add({
        severity: "success",
        summary: t("stock.delete.title"),
        detail: t(`stock.delete.success`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      const used = !!errors[0]?.message;
      toast.add({
        severity: used ? "info" : "warn",
        summary: t("stock.delete.title"),
        detail: t(`stock.${used ? "delete.used" : "failed"}`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });
  onError((e) => {
    toast.add({
      severity: "warn",
      summary: t("stock.delete.title"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  function deleteStock(id: string, productId: number) {
    const data = {
      header: t("stock.delete.title"),
      message: t("stock.removeWarning"),
      group: "dialog",
      accept: () =>
        void mutate(
          { id },
          {
            update: (cache, { data }) => {
              if (data?.deleteStock) {
                const products: Product[] = cloneDeep(
                  cache.readQuery<ProductsData, QueryProductsArgs>({
                    query: PRODUCTS,
                    variables: { activityId: activeActivity.value.id },
                  }).products
                );
                const index = products.findIndex((p) => p.id === productId);
                products[index].stocks = products[index].stocks.filter(
                  (s) => s.id !== id
                );
                cache.writeQuery<ProductsData, QueryProductsArgs>({
                  query: PRODUCTS,
                  data: { products },
                  variables: { activityId: activeActivity.value.id },
                });
              }
            },
          }
        ),
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-danger",
    };
    confirm.require(data);
  }
  return { removeLoading, deleteStock };
};
