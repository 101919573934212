
import { defineComponent, ref } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { activeActivity } from "@/plugins/i18n";

const FILTERS_ = {
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  "product.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  quantity: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  qStock: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  initial: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  price: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export default defineComponent({
  name: "DocumentMovements",
  props: ["movements", "selected"],
  emits: ["setDocument"],
  setup() {
    const filters = ref({ ...FILTERS_ });
    return {
      clearFilter: () => {
        Object.assign(filters.value, FILTERS_);
        filters.value.global.value = "";
      },
      filters,
      currency: activeActivity.value.currencySymbol,
    };
  },
});
