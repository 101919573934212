import {
  Document,
  DocumentPagination,
  Movement,
  PaginateMovementInput,
  QueryPaginateDocumentsArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { InitialPagination, PAGINATION_META } from "@/graphql/utils/pagination";
import { useQuery } from "@vue/apollo-composable";
import { computed, reactive, ref } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { MOVEMENT_FIELDS } from "@/graphql/movement/movement";
import { FilterMatchMode, FilterOperator } from "primevue/api";

type DocumentsData = {
  paginateDocuments: DocumentPagination;
};

const DOCUMENT = gql`
    query PaginateDocuments($input: PaginateMovementInput!) {
        paginateDocuments(input: $input) {
            items{
                id
                type
                createdAt
                deletedAt
                number
                movements{
                  ${MOVEMENT_FIELDS}
                  product {
                      id
                      name
                  }
                  stock {
                    id
                    expiredAt
                  }
                }
            }
            ${PAGINATION_META}
        }
    }
`;

const FILTERS_ = {
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  number: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  type: {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  productCount: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export const useDocuments = () => {
  const selected = ref<Document>(null);
  const filters = ref(FILTERS_);
  function clearFilter() {
    Object.assign(filters.value, FILTERS_);
    filters.value.global.value = "";
  }
  const print = ref(false);
  const input = reactive<PaginateMovementInput>({
    limit: 10,
    activityId: activeActivity.value.id,
    page: 1,
  });
  const { loading, result, refetch } = useQuery<
    DocumentsData,
    QueryPaginateDocumentsArgs
  >(DOCUMENT, { input }, { fetchPolicy: "network-only" });
  const document = computed(() => {
    const res = result.value?.paginateDocuments || InitialPagination;
    input.activityId = activeActivity.value.id;
    return {
      ...res,
      items: res.items.map((doc) => ({
        ...doc,
        type: doc.type === 2 ? 0 : doc.type,
        createdAt: new Date(doc.createdAt),
        updatedAt: new Date(doc.deletedAt),
        productCount: countProducts(doc.movements),
      })),
    };
  });
  function countProducts(movements: Movement[]) {
    return movements.reduce(
      (cum, cur) =>
        cum.includes(cur.product.id) ? cum : cum.concat([cur.product.id]),
      []
    ).length;
  }
  function setDocument(doc: Document) {
    selected.value = doc;
    print.value = false;
  }
  const movements = computed(() =>
    (selected.value?.movements || []).map((mvt) => {
      const quantity = mvt.quantity * (mvt.motif > 0 ? 1 : -1);
      return {
        ...mvt,
        quantity,
        initial: mvt.qStock - quantity,
        stock: {
          ...mvt?.stock,
          expiredAt: mvt?.stock?.expiredAt
            ? new Date(mvt.stock.expiredAt)
            : null,
        },
      };
    })
  );
  return {
    input,
    document,
    loading,
    refetch,
    selected,
    setDocument,
    print,
    clearFilter,
    filters,
    movements,
  };
};
